import styles from './styles.module.scss'
import { SearchInterface } from '@shared/interfaces/search.interface'
import { isEmptyArray } from '@utils/arrays'
import SearchItems from '@ui/search-result/search-items'
import RenderIf from '@utils/render-if'
import { useAppDispatch, useAppSelector } from '@root/store'
import { selectChannelContentTypeLabelsByChannelPathname } from '@store/channel-slice/_selectors'
import { ContentTypeLabelsEnum } from '@shared/enums/content-type-labels.enum'
import ContentCard from '@ui/_content/content-card'
import { ReactElement, useMemo } from 'react'
import { generateContentPath } from '@utils/content/generate-content-path'
import Link from 'next/link'
import useRedirect from '@hooks/use-redirect'
import useChannelSearch from '@hooks/search/use-channel-search'
import { useRouter } from 'next/router'
import { setSearchText, toggleInputVisibility } from '@store/search-slice'

interface Props {
  results: SearchInterface[]
  contentType: ContentTypeLabelsEnum
  channelPathname: string
}

export default function SearchResultBlock({
  results,
  contentType,
  channelPathname,
}: Props): ReactElement {
  const router = useRouter()
  const { handleClose } = useChannelSearch()

  // Get content type labels from store
  const contentTypeLabels = useAppSelector(
    selectChannelContentTypeLabelsByChannelPathname(channelPathname),
  )

  // Get matching content type label if it exists otherwise return undefined so that the default label is used
  const getMatchingContentTypeLabelIfExists = (contentType: ContentTypeLabelsEnum) => {
    if (!isEmptyArray(contentTypeLabels)) {
      return contentTypeLabels.find((label) => label.contentType === contentType)?.displayName
    }
    return undefined
  }

  // Map to get the search block header for each content type, Use the content type labels for the channel from the BE
  // Or default to standard labels
  const searchBlockHeaderMap: Record<ContentTypeLabelsEnum, string> = {
    [ContentTypeLabelsEnum.LIVE]:
      getMatchingContentTypeLabelIfExists(ContentTypeLabelsEnum.LIVE) ?? 'Live Streams',
    [ContentTypeLabelsEnum.VOD]:
      getMatchingContentTypeLabelIfExists(ContentTypeLabelsEnum.VOD) ?? 'Videos',
    [ContentTypeLabelsEnum.AUDIO]:
      getMatchingContentTypeLabelIfExists(ContentTypeLabelsEnum.AUDIO) ?? 'Audios',
    [ContentTypeLabelsEnum.BUNDLE]:
      getMatchingContentTypeLabelIfExists(ContentTypeLabelsEnum.BUNDLE) ?? 'Bundles',
    [ContentTypeLabelsEnum.EXTERNAL_LINK]:
      getMatchingContentTypeLabelIfExists(ContentTypeLabelsEnum.EXTERNAL_LINK) ??
      'Virtual Sessions',
    [ContentTypeLabelsEnum.CHAPTER]:
      getMatchingContentTypeLabelIfExists(ContentTypeLabelsEnum.CHAPTER) ?? 'Chapters',
  }

  const dispatch = useAppDispatch()

  const renderResult = useMemo(() => {
    const redirectToContent = async (contentUrl: string) => {
      await router.push(contentUrl)
      dispatch(setSearchText(''))
      dispatch(toggleInputVisibility())
    }

    return results?.map((result, index) => {
      const contentLink = generateContentPath(result.contentId, result.channelUrl)
      return (
        <button
          className={styles.buttonAction}
          key={result.contentId}
          onClick={() => redirectToContent(contentLink)}
        >
          <ContentCard
            contentId={result.contentId}
            channelName={result.channelName}
            contentType={result.contentType}
            channelLogo={result.channelLogo}
            contentThumbnail={result.contentThumbnail}
            contentTitle={result.contentTitle}
            channelUrl={channelPathname}
          />
        </button>
      )
    })
  }, [results])

  return (
    <RenderIf isTrue={!isEmptyArray(results)}>
      <div className={styles.wrapper}>
        <h2>{searchBlockHeaderMap[contentType]}</h2>
        <div className={styles.content}>{renderResult}</div>
      </div>
    </RenderIf>
  )
}
