import { NAV_HEIGHT } from '@shared/constants/root.constants'
import { useEffect } from 'react'

// Time out of 200 ms. To allow components to render before the page tries to scroll
const TIMEOUT_BEFORE_SCROLLING_TO_ANCHOR = 200

/**
 * Scroll to anchor if there is one in the url
 * We need a hook to handle this because we have a fixed header that will cover the top
 * of the component that is being anchored. This will force the page to scroll to the
 * anchor minus the height of the header (77 pixels).
 */
export default function useScrollToAnchor() {
  useEffect(() => {
    // Initialise timer so we can remove when component unmounts
    let timer: NodeJS.Timeout
    const scrollToAnchor = () => {
      // Add a delay because components render slower than this hook takes to run.
      timer = setTimeout(() => {
        if (typeof document !== 'undefined' && window.location.hash) {
          const targetElement = document.querySelector(window.location.hash) as HTMLElement

          if (targetElement) {
            window.scrollTo({
              top: targetElement.offsetTop - NAV_HEIGHT,
              behavior: 'smooth',
            })
          }
        }
      }, TIMEOUT_BEFORE_SCROLLING_TO_ANCHOR)
    }

    scrollToAnchor()
    window.addEventListener('hashchange', scrollToAnchor)

    //Adding scroll smooth only when we need it
    const html = document.querySelector('html')
    if (typeof html !== 'undefined') {
      html.classList.add('scrollSmooth')
    }

    return () => {
      window.removeEventListener('hashchange', scrollToAnchor)
      if (typeof html !== 'undefined') {
        html.classList.remove('scrollSmooth')
      }
      clearTimeout(timer)
    }
  }, [])
}
