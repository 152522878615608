import { useAppSelector as useSel } from '@root/store'
import { selectChannelData } from '@store/channel-slice/_selectors'

interface ChannelImagesInterface {
  bannerImage: string
  avatar: string
  channelPathname: string
}

export default function useChannelImages({
  bannerImage,
  avatar,
  channelPathname,
}: ChannelImagesInterface) {
  // Grab the channel data from the store
  const channel = useSel(selectChannelData(channelPathname))

  // Initialise the images fetched from the BE directly (These are the latest up-to-date images with no caching)
  const bannerImageFetched = channel?.bannerImage
  const avatarFetched = channel?.logo

  // Set the images to use as the images passed as props from get static props
  let bannerImageToUse = bannerImage
  let avatarToUse = avatar

  // If the images from the BE are available and are different to the images passed as props
  // Then use the images from the BE. Otherwise use the images passed as props
  if (bannerImageFetched && avatarFetched) {
    bannerImageToUse = bannerImageFetched === bannerImage ? bannerImage : bannerImageFetched
    avatarToUse = avatarFetched === avatar ? avatar : avatarFetched
  }

  return {
    bannerImageToUse,
    avatarToUse,
    bannerImageAlt: channel?.bannerImageAlt ?? channel?.displayName,
  }
}
