import style from './common.module.css'
import { Img } from '@library/_images/img'
import { buildCoverImageToUse } from '@root/utils/image'
import { useEffect, useState } from 'react'

export interface Props {
  image: string
  imageAlt: string
}

export function CoverImage({ image, imageAlt }: Props): JSX.Element {
  const { src, srcSet, sizes } = buildCoverImageToUse(image)

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [image])

  return (
    <div className={loading ? style.loading : ''}>
      <Img
        alt={imageAlt}
        aspectRatio={'32/9'}
        backgroundColor={'transparent'}
        className={style.heroImage}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
      />
    </div>
  )
}
