import styles from './styles.module.scss'
import { useAppSelector } from '@root/store'
import {
  selectPassDataByPassId,
  selectSubscriptionDataBySubscriptionId,
} from '@store/channel-slice/_selectors'
import { ToSubscribeButton } from '@library/_flows/_subscribe-channel-flow/to-subscribe-button'
import { motion } from 'framer-motion'
import { FADE_IN } from '@shared/constants/root.constants'
import { useSubscribeBySubscriptionId } from '@hooks/use-subscribe-channel-flow-state'
import SubscriptionTerms from '@features/_ui/subscription-terms/index.'
import RenderIf from '@root/utils/render-if'
import { hasProp } from '@root/utils/has-prop'
import PassTerms from '@features/_ui/pass-terms/index.'
import { ToBuyChannelPassButton } from '@library/_flows/_channel_pass-flow/to-buy-channel-pass-button'

export interface Props {
  channelPathname: string
  subscriptionId?: string
  passId?: string
}

export default function SubscriptionPlanCard({
  channelPathname,
  subscriptionId,
  passId,
}: Props): JSX.Element {
  const subscriptionStatus = useSubscribeBySubscriptionId({ channelPathname, subscriptionId })
  const subscription = useAppSelector(
    selectSubscriptionDataBySubscriptionId(channelPathname, subscriptionId),
  )
  const pass = useAppSelector(selectPassDataByPassId(channelPathname, passId))

  return (
    <motion.div initial="hidden" animate="visible" variants={FADE_IN} className={styles.wrapper}>
      <RenderIf isTrue={hasProp(subscriptionId)}>
        <div className={styles.imageWrapper}>
          <img src={subscription?.imageUrl} alt={subscription?.title} />
          <div className={styles.imageOverlay}>
            <h3>{subscription?.title}</h3>
            <span>{subscription?.description}</span>
          </div>
        </div>
        <div className={styles.textButtonWrapper}>
          <h3>{subscription?.price}</h3>
          <SubscriptionTerms
            channelPathname={channelPathname}
            subscriptionId={subscriptionId}
            variant={'panel'}
          />
          <ToSubscribeButton
            channelPathname={channelPathname}
            subscriptionStatus={subscriptionStatus}
            subscriptionId={subscriptionId}
          >
            Subscribe now
          </ToSubscribeButton>
        </div>
      </RenderIf>
      <RenderIf isTrue={hasProp(passId)}>
        <div className={styles.imageWrapper}>
          <img src={pass?.imageUrl} alt={pass?.title} />
          <div className={styles.imageOverlay}>
            <h3>{pass?.title}</h3>
            <span>{pass?.description}</span>
          </div>
        </div>
        <div className={styles.textButtonWrapper}>
          <span className={styles.access}>
            Access for {pass?.durationInDays} {pass?.durationInDays > 1 ? 'days' : 'day'}
          </span>
          <h3>{pass?.price}</h3>
          <PassTerms channelPathname={channelPathname} passId={passId} variant={'panel'} />
          <ToBuyChannelPassButton channelPathname={channelPathname} passId={passId} />
        </div>
      </RenderIf>
    </motion.div>
  )
}
