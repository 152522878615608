import type { ReactElement } from 'react'
import type { GetStaticPaths, GetStaticProps } from 'next'
import { ChannelPage } from '@components/channel-page'
import { HtmlHead } from '@library/head-html/html-head'
import { REVALIDATE_TIME_TEN_MINS } from '../../constants'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import Error404 from '@ui/404'
import useTheme2024 from '@hooks/_theme/use-theme-2024'
import dynamic from 'next/dynamic'
import useFavicon from '@hooks/_theme/use-favicon'
import useChannelSeoData from '@hooks/_seo/use-channel-seo-data'
import useWhiteLabel from '@hooks/_white-label/use-white-label'
import { ChannelInterfacesSSR } from '@shared/interfaces/channel/channel.interfaces'
import fetchChannelSSR from '@apis/channels-microservice/fetch-channel-ssr'
const Layout = dynamic(() => import('@components/layout'), { ssr: false })

export type Props = {
  channel: ChannelInterface
}

export default function Index({ channel }: Props): ReactElement {
  const { isWhiteLabel } = useWhiteLabel(channel)
  const favicon = useFavicon(channel)
  const {
    title,
    description,
    shareImage,
    shareImageAlt,
    url,
    canBeIndexed,
    organisationMarketing,
  } = useChannelSeoData(channel)

  // Activate Vidzing theme 2024
  useTheme2024(isWhiteLabel)

  // if Channel doesn't exist show 404
  if (!channel || !channel?.channelId) {
    return <Error404 />
  }

  return (
    <>
      <HtmlHead
        title={title}
        description={description}
        imagePathname={shareImage}
        imageAlt={shareImageAlt}
        url={url}
        noIndex={canBeIndexed}
        organisationMarketingTracking={organisationMarketing}
        favicon={favicon}
      />
      <Layout channel={channel}>
        <ChannelPage channel={channel} />
      </Layout>
    </>
  )
}

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { channel_pathname } = context.params as ChannelInterfacesSSR
  const channel = await fetchChannelSSR(channel_pathname)

  // Return 404 if the channel doesn't exist
  if (!channel) {
    return {
      notFound: true,
    }
  }

  return { props: { channel }, revalidate: REVALIDATE_TIME_TEN_MINS }
}
