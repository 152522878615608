import {
  selectIsSubscribedByChannelPathname,
  selectIsSubscribedBySubscriptionId,
  selectSubscriberPropByChannelPathname,
} from '@store/subscriber-slice/_selectors'
import { selectIsSubscribableByChannelPathname } from '@store/channel-slice/_selectors'
import { selectUserId } from '@store/user-slice/_selectors'
import { useAppSelector as useSel } from '@root/store'
import { ChannelSubscribeStateType } from '@shared/types/channel-subscribe-state.type'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSubscriptionStatusThunk } from '@store/subscriber-slice/_thunks'
import { setSubscribedByChannelPathname } from '@store/subscriber-slice'

interface Props {
  channelPathname: string
  subscriptionId: string
}
export function useSubscribeBySubscriptionId({
  channelPathname,
  subscriptionId,
}: Props): ChannelSubscribeStateType {
  const dispatch = useDispatch()
  const userId = useSel(selectUserId)
  const isSubscribedBySubscriptionId = useSel(selectIsSubscribedBySubscriptionId(subscriptionId))

  // Check if user is subscribed by channel pathname
  // If yes then don't render this rail as user is already subscribed
  const isSubscribedByChannelPathname = useSel(selectIsSubscribedByChannelPathname(channelPathname))

  // If user is logged in and isSubscribedBySubscriptionId is defined fetch the subscriptions status by id
  useEffect(() => {
    if (subscriptionId && userId && isSubscribedBySubscriptionId === undefined) {
      dispatch(getSubscriptionStatusThunk({ channelPathname, subscriptionId }))
    }
  }, [userId, isSubscribedBySubscriptionId, subscriptionId, channelPathname, dispatch])

  // If user is subscribed to a subscription by id and not by channel pathname then populate the state
  // so user is subscribed by channel pathname. This can happen with a super channel if a user loads home page
  // Then routes to a sub channel. The new sub channel will not be saved as subscribed as the BE is not hit again
  useEffect(() => {
    if (isSubscribedBySubscriptionId && !isSubscribedByChannelPathname) {
      dispatch(
        setSubscribedByChannelPathname({
          channelPathname,
          isSubscribed: isSubscribedBySubscriptionId,
        }),
      )
    }
  }, [channelPathname, isSubscribedByChannelPathname, isSubscribedBySubscriptionId])

  /**
   * userId = '' THEN 'isNotSubscribed'
   * isSubscribedBySubscriptionId = true THEN 'isSubscribedBySubscriptionId'
   * isSubscribedBySubscriptionId = false THEN 'isNotSubscribed'
   */
  return userId === ''
    ? 'isNotSubscribed'
    : isSubscribedBySubscriptionId
      ? 'isSubscribed'
      : 'isNotSubscribed'
}
