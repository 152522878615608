import { useAppSelector } from '@root/store'
import { selectIsLoading, selectSearchData, selectSearchText } from '@store/search-slice/_selectors'
import { SearchResultsInterface } from '@shared/interfaces/search.interface'
import { ContentTypeLabelsEnum } from '@shared/enums/content-type-labels.enum'

export default function useGetSearchResults(channelPathname: string) {
  const searchText = useAppSelector(selectSearchText)
  const searchData = useAppSelector((state) =>
    selectSearchData(state, {
      searchText,
      channelPathname,
    }),
  )
  const isLoading = useAppSelector(selectIsLoading)

  // Create search results object to be populated with search data results
  const searchResults: SearchResultsInterface = {
    liveResults: [],
    vodResults: [],
    audioResults: [],
    externalLinkResults: [],
    bundleResults: [],
    chapterResults: [],
  }

  // Split search data results into live, vod, audio, external link, bundle and chapter result arrays by content type
  searchData?.forEach((result) => {
    switch (result.contentType) {
      case ContentTypeLabelsEnum.LIVE:
        searchResults.liveResults.push(result)
        break
      case ContentTypeLabelsEnum.VOD:
        searchResults.vodResults.push(result)
        break
      case ContentTypeLabelsEnum.AUDIO:
        searchResults.audioResults.push(result)
        break
      case ContentTypeLabelsEnum.EXTERNAL_LINK:
        searchResults.externalLinkResults.push(result)
        break
      case ContentTypeLabelsEnum.BUNDLE:
        searchResults.bundleResults.push(result)
        break
      case ContentTypeLabelsEnum.CHAPTER:
        searchResults.chapterResults.push(result)
        break
      default:
        break
    }
  })

  return { isLoading, searchResults, searchData, searchText }
}
