import { useEffect } from 'react'
import { AFTER_AUTH_PATHNAME_PARAMS_KEY } from '@components/welcome-page/_hooks/use-go-back-to-current-url'

/**
 * Hook to update the after auth path stored in local storage to route the user
 * back to their previous path (channel or content page)
 *
 * We need this hook for some new white label functionality which can force the path to be
 * hidden using rewrites. The other hook that sets the path in local storage uses the url in the browser.
 * Using rewrites though can force the actual path to be hidden from the url.
 *
 * This hook will grab the path stored in local storage and if it is different from the path expected it will update it
 *
 * For example we have a rewrite for the ftps (from the pilots seat) channel live in production.
 * The custom domain name used is: https://fromthepilotsseat.org and that is rewritten to vidzing.tv/ftps.
 * So the user will see the correct page rendered for ftps however the url stays as https://fromthepilotsseat.org.
 * Meaning the other hook that sets the after auth pathname in local storage sets the path as / and not /ftps because
 * the url does not contain ftps.
 *
 * This hook will be called from the channel and content page and will pass the path of the page due to the payload from the BE.
 * i.e. When the ftps channel loads the channel payload from the BE has the channelUrl attribute which will be ftps.
 * The channel will call this hook with the path '/ftps' parameter passed and the local storage will be updated with
 * the correct path for the user to return to.
 * @param path
 */
export default function useUpdateAfterAuthPath(path: string): void {
  useEffect(() => {
    const afterAuthPath = sessionStorage.getItem(AFTER_AUTH_PATHNAME_PARAMS_KEY)

    if (path !== afterAuthPath) {
      sessionStorage.setItem(AFTER_AUTH_PATHNAME_PARAMS_KEY, path)
    }
  }, [path])
}
