import { ReactElement, useEffect, useMemo, useRef } from 'react'
import { isEmptyString } from '@utils/strings'
import useDebounce from '@hooks/use-debounce'
import { ONE_SECOND_MS } from '@root/constants'
import styles from './styles.module.scss'
import CloseIcon from '@ui/icons/close'
import { MagnifyingGlassIcon } from '@library/_form/input-field/_icons/magnifying-glass-icon'
import { useAppProvider } from '@providers/app-provider/_contexts'
import { SearchRequest } from '@shared/interfaces/search.interface'
import { useRouter } from 'next/router'

export interface Props {
  searchBy: string
  onSearch: (search: SearchRequest) => void
  backButton?: ReactElement
}

export default function SearchInput({ searchBy, onSearch, backButton }: Props): ReactElement {
  const router = useRouter()

  // Refs
  const inputRef = useRef<HTMLInputElement>()

  // Provider
  const { category, referer } = useAppProvider()

  // Trigger search
  const searchDataByInput = () => {
    const searchBy = inputRef.current.value
    if (!isEmptyString(searchBy)) {
      onSearch({ word: searchBy, category })
    }
  }

  // Debounce hook
  const handleTriggerFunction = useDebounce(ONE_SECOND_MS, searchDataByInput)

  useEffect(() => {
    if (inputRef.current !== null && inputRef.current) {
      inputRef.current.value = searchBy
      inputRef.current.focus()
    }
  }, [searchBy, inputRef])

  // Displaying back button. On channel search, we have a different action than explore and home pages
  const renderBackButton = useMemo(() => {
    const handleOnBack = () => {
      try {
        router.back()
      } catch (e) {
        router.push('/')
      }
    }

    return !backButton ? (
      <button onClick={handleOnBack}>
        Close <CloseIcon />
      </button>
    ) : (
      backButton
    )
  }, [backButton, router])

  return (
    <div className={styles.search}>
      <div className={styles.close}>{renderBackButton}</div>
      <h1>Search</h1>
      <div className={styles.searchWrapper}>
        <MagnifyingGlassIcon />
        <input ref={inputRef} className={styles.input} onChange={handleTriggerFunction} />
      </div>
    </div>
  )
}
