import Slider from '@ui/slider'
import { CARD_SLIDER_CONFIG } from './_constants'
import { useAppSelector, useAppSelector as useSel } from '@root/store'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { selectUserId } from '@store/user-slice/_selectors'
import { selectFilteredOutUnlistedSubscriptionIdsByChannelPathname } from '@store/channel-slice/_selectors'
import useHasSubscriptionsOrPass from '@hooks/use-has-subscriptions-or-passes'
import { useEffect, useMemo } from 'react'
import { getSubscriptionStatusThunk } from '@store/subscriber-slice/_thunks'
import { selectIsSubscribedByChannelPathname } from '@store/subscriber-slice/_selectors'
import useScrollToAnchor from '@hooks/use-scroll-to-anchor'
import SubscriptionPlanCard from '@ui/subscription-plan-card'
import RenderIf from '@utils/render-if'
import { isEmptyArray } from '@utils/arrays'
import { selectHasActivePassByChannelPathname } from '@store/pass-slice/_selectors'
import { SUBSCRIPTION_PLANS_RAIL_ID } from '@shared/constants/rails.constants'

export interface Props {
  channelPathname: string
  addPaddingTop?: boolean
}

export default function SubscriptionsRailsV2({
  channelPathname,
  addPaddingTop,
}: Props): JSX.Element {
  const { settings, showButtons, buttonsVariant } = CARD_SLIDER_CONFIG

  //
  const dispatch = useDispatch()
  const userId = useSel(selectUserId)

  const { subscriptionIds, passes, hasSubscriptionOrPass } =
    useHasSubscriptionsOrPass(channelPathname)
  // Filter out the unlisted subscription ids to render on the subscriptions rail
  const filteredSubscriptionIds = useSel((state) =>
    selectFilteredOutUnlistedSubscriptionIdsByChannelPathname(state, channelPathname),
  )

  // Check if user is subscribed by subscription id
  useEffect(() => {
    subscriptionIds.forEach((subscriptionId) => {
      if (subscriptionId && userId) {
        dispatch(getSubscriptionStatusThunk({ channelPathname, subscriptionId: subscriptionId }))
      }
    })
  }, [subscriptionIds, channelPathname, userId])

  // Check if user is subscribed by channel pathname
  // If yes then don't render this rail as user is already subscribed
  const isSubscribed = useAppSelector(selectIsSubscribedByChannelPathname(channelPathname))
  const isPlanSubscribed = useAppSelector(selectHasActivePassByChannelPathname(channelPathname))

  // Prepare components
  const renderCardRail = useMemo(() => {
    const renderCards = []
    if (filteredSubscriptionIds) {
      filteredSubscriptionIds.map((id, index) =>
        renderCards.push(
          <SubscriptionPlanCard
            channelPathname={channelPathname}
            subscriptionId={id}
            key={index}
          />,
        ),
      )
    }

    if (passes) {
      passes.map((pass, index) => {
        renderCards.push(
          <SubscriptionPlanCard
            channelPathname={channelPathname}
            passId={pass.passId}
            key={index}
          />,
        )
      })
    }

    return renderCards
  }, [filteredSubscriptionIds, passes, channelPathname])

  // Use the scroll to anchor hook to handle href anchors.
  // This has to be on the page that the anchor is on so that the div or element is rendered before
  // the hook tries to scroll to it.
  useScrollToAnchor()

  const canRender = () => {
    if (isSubscribed || !hasSubscriptionOrPass) return false
    if (!isEmptyArray(filteredSubscriptionIds) || !isEmptyArray(passes)) return true
  }

  return (
    <RenderIf isTrue={canRender()}>
      <section
        className={`${styles.railCards} ${addPaddingTop ? styles.addPaddingTop : ''}`}
        aria-label={'Subscriptions'}
        id={SUBSCRIPTION_PLANS_RAIL_ID}
      >
        <h2 className={styles.title}>Subscriptions</h2>
        <Slider
          elements={renderCardRail}
          settings={settings}
          showButtons={showButtons}
          buttonsVariant={buttonsVariant}
          isRailContent
        />
      </section>
    </RenderIf>
  )
}
