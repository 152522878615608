import style from './styles.module.scss'
import { ChannelDescription } from '@library/_channel/channel-description'
import { ChannelName } from '@library/_channel/channel-name'
import { CreatorLogo } from '@library/_creator'
import { AVATAR_CHANNEL_THUMB } from '@root/constants'
import { useAppSelector as useSel } from '@root/store'
import { selectChannelData } from '@store/channel-slice/_selectors'
import { PREVIEW_CHANNEL_AVATAR } from '@shared/constants/root.constants'
import { selectIsSubscribedByChannelPathname } from '@store/subscriber-slice/_selectors'
import RenderIf from '@utils/render-if'
import AccessBadge from '@ui/access-badge'
import { selectHasActivePassByChannelPathname } from '@store/pass-slice/_selectors'

export type Props = {
  channelPathname: string
  avatar: string
}

export function IntroV2({ channelPathname, avatar }: Props): JSX.Element {
  const channel = useSel(selectChannelData(channelPathname))

  // Check if user is subscribed to channel
  const isSubscribed = useSel(selectIsSubscribedByChannelPathname(channelPathname))
  const hasActivePass = useSel(selectHasActivePassByChannelPathname(channelPathname))

  return (
    <div className={style.wrapper}>
      <CreatorLogo
        logoSrc={avatar ?? PREVIEW_CHANNEL_AVATAR}
        logoAlt={channel?.logoAlt ?? channel?.displayName}
        channelPathname={channelPathname}
        className={style.logo}
        width={AVATAR_CHANNEL_THUMB}
      />
      <ChannelName channelPathname={channelPathname} hn="h2" className={style.name} />
      <div className={style.descriptionButtons}>
        <ChannelDescription channelPathname={channelPathname} />
        <RenderIf isTrue={isSubscribed}>
          <AccessBadge channelPathname={channelPathname} label={'Subscribed'} />
        </RenderIf>
      </div>
    </div>
  )
}
