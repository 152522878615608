import Header from '../../library/header'
import PageFrame from '../../library/page-frame'
import style from './styles.module.scss'
import { ChannelHeader } from './main/channel-header'
import { DialogProvider } from '../dialog-provider'
import { Footer as FooterOld } from '@library/footer'
import { useApiData } from './_hooks/use-page-data'
import { ReactElement, ReactNode, useState } from 'react'
import ChannelAccessDenied from '@library/_channel/channel-access-denied'
import { useSetPageState } from '@components/channel-page/_hooks/use-set-page-state'
import { LoadingBox } from '@library/_loading'
import { VidzingPageState } from '../../constants'
import RenderIf from '@root/utils/render-if'
import FeaturedCard from '@features/_ui/featured-card'
import UseLoadOtherHooks from '@components/channel-page/_hooks/use-load-other-hooks'
import SearchResult from '@features/_ui/search-result'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import useUpdateAfterAuthPath from '@hooks/use-update-after-auth-path'
import { parseBoolean } from '@utils/connascence'
import { FooterVariantEnum } from '@shared/enums/layout/footer.enum'
import Footer from '@components/layout/footer'
import usePageThemeToUse from '@hooks/_theme/use-page-theme-to-use'
import SuperChannelCreatorRailsV2 from '@ui/rails/super-channel-channels'
import SubscriptionsRailsV2 from '@ui/rails/subscriptions'
import { useAppSelector } from '@root/store'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import Rails from '@features/rails'
import { CreatorLinksV2 } from '@ui/channel/creator-link'

export type Props = {
  // Pass the whole channel from static props so there are no flashes in the page of required data
  channel: ChannelInterface
}

export function ChannelPage({ channel }: Props): ReactElement {
  // Hook to update the after auth path if custom domain is used
  useUpdateAfterAuthPath(`/${channel.channelUrl}`)

  // We grab the logo and banner image from the channel object passed from static props so there is no blink in image
  const { channelUrl: channelPathname, logo: avatar, bannerImage, organisation } = channel
  const { whiteLabel } = organisation

  useApiData(channelPathname)
  const [pageState, setPageState] = useState<VidzingPageState>('loading')
  const [deniedEmail, setDeniedEmail] = useState<string>('')
  const [requestEmail, setRequestEmail] = useState<string>('')

  const isNewTheme = parseBoolean(process.env.VIDZING_THEME_2024)
  // Hook to set the channel page state
  useSetPageState(channelPathname, setPageState, setDeniedEmail, setRequestEmail)

  const { isSuperChannel, customStyles, hasSearch } = UseLoadOtherHooks({ channelPathname })

  const mobileChannelHeaderFlag = useAppSelector(
    selectAppFeatureFlagStatusByName('flagBugMobileChannelHeader'),
  )
  // Set theme to use
  const pageFrameStyleToUse = usePageThemeToUse()

  // TODO: Hiding featured rail for now. We need to improve the feature
  const canRenderFeaturedRail = false

  const componentMap: Record<VidzingPageState, ReactNode> = {
    loading: (
      // If the channel is white labelled use white label as pageFrame for loader
      <PageFrame variant={!!whiteLabel ? 'whiteLabel' : pageFrameStyleToUse}>
        <LoadingBox />
      </PageFrame>
    ),
    access: (
      <PageFrame
        variant={mobileChannelHeaderFlag ? 'absoluteHeaderWithPaddingTopMobile' : 'absoluteHeader'}
        styles={customStyles}
      >
        <Header
          className={!whiteLabel ? style.headerV2 : style.header}
          hasSearch={true}
          whiteLabel={whiteLabel}
          channelPathname={channelPathname}
        />
        <main>
          <RenderIf
            isTrue={hasSearch}
            trueComponent={<SearchResult channelPathname={channelPathname} />}
          >
            <ChannelHeader
              channelPathname={channelPathname}
              bannerImage={bannerImage}
              avatar={avatar}
            />
            {canRenderFeaturedRail && <FeaturedCard channelPathname={channelPathname} />}
            <SubscriptionsRailsV2 channelPathname={channelPathname} />
            <RenderIf isTrue={isSuperChannel}>
              <div className={style.railsChannels}>
                <SuperChannelCreatorRailsV2 channelPathname={channelPathname} />
              </div>
            </RenderIf>
            <Rails channelPathname={channelPathname} />
          </RenderIf>
        </main>

        {!whiteLabel ? (
          <div className={style.preFooter}>
            <div className={style.extraBoxInChannel}>
              <CreatorLinksV2 channelPathname={channelPathname as string} />
            </div>
            <Footer variant={FooterVariantEnum.COMPACT} channelPathname={channelPathname} />
          </div>
        ) : (
          <FooterOld
            channelPathname={channelPathname}
            variant="linksAndRegister"
            whiteLabel={whiteLabel}
          />
        )}
      </PageFrame>
    ),
    denied: (
      <PageFrame variant="vidzing2024Theme">
        <ChannelAccessDenied
          userEmail={deniedEmail}
          channelPathname={channelPathname}
          requestEmail={requestEmail}
        />
      </PageFrame>
    ),
  }

  return <DialogProvider>{componentMap[pageState]}</DialogProvider>
}
