import { parseBoolean } from '@utils/connascence'
import { useEffect } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

export default function useTheme2024(hasWhitelabel: boolean = false) {
  // Featured flag for Vidzing 2024 theme
  const isNewTheme = parseBoolean(process.env.VIDZING_THEME_2024)

  /**
   * Function to toggle theme from html and body
   * @param action
   */
  const toggleTheme = (action: 'add' | 'remove') => {
    try {
      const main = document.documentElement
      const body = document.body
      if (main && body) {
        if (action === 'add') {
          main.classList.add('vidzing2024-theme-body')
          body.classList.add('vidzing2024-theme-body')
        } else {
          main.classList.remove('vidzing2024-theme-body')
          body.classList.remove('vidzing2024-theme-body')
        }
      }
    } catch (error) {
      datadogLogs.logger.warn('Error toggleable Vidzing 2024 theme', {}, error)
      throw error
    }
  }

  /**
   * Set Featured Vidzing 2024 theme
   * 1 - If a user is loading the "explore" page, I activate the theme without straight away
   * 2 - If the user is in a channel, I wait until fetchStatus is success to determinate if we need to apply the
   * new theme or not.
   * e.g.: Combat sport needs a pure black background, we can't use our new background colour in there.
   */
  useEffect(() => {
    if (isNewTheme) {
      if (hasWhitelabel) {
        toggleTheme('remove')
      } else {
        toggleTheme('add')
      }
    }
  }, [hasWhitelabel, isNewTheme])
}
