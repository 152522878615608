import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useEffect } from 'react'
import fbAuth from '@apis/firebase'
import {
  CHANNEL_ACCESS_DENIED_EMAIL_SESSION_KEY,
  CHANNEL_ACCESS_STATUS_SESSION_KEY,
  ChannelAccessStatusSessionEnum,
} from '@components/welcome-page/main/form-context-provider/section/channel-access-activate/constants'
import getChannelByPathname from '@apis/channels-microservice/get-channel-by-pathname'
import { VIDZING_SUPPORT_EMAIL, VidzingPageState } from '@root/constants'
import { useAppSelector } from '@root/store'
import { selectChannelPropByChannelPathname, selectStatus } from '@store/channel-slice/_selectors'
import getUserById from '@apis/users-microservice/get-user-by-id'
import { isGreaterThanZero } from '@root/utils/connascence'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { ChannelAccessEnum } from '@shared/enums/channel-access.enum'
import UseSelectWhiteLabelData from 'hooks/white-label/use-select-white-label-data'
import useSearchInUrl from '@hooks/search/use-search-in-url'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { useSelector } from 'react-redux'
import { GetByChannelPathNameParamsInterface } from '@apis/channels-microservice/_entities/_types'
import getChannelByPathnameV2 from '@apis/channels-microservice/get-channel-by-pathname-v2'

export function useSetPageState(
  channelPathname: string,
  setPageState: Dispatch<SetStateAction<VidzingPageState>>,
  setDeniedEmail: Dispatch<SetStateAction<string>>,
  setRequestEmail: Dispatch<SetStateAction<string>>,
): void {
  const router = useRouter()
  const fetchStatus = useAppSelector(selectStatus) ?? 'idle'

  // white label
  const { dataLoaded: IsWhiteLabelDataLoaded } = UseSelectWhiteLabelData()

  // Check if url has search term on page load and show results
  const { checkSearchParam } = useSearchInUrl(channelPathname)

  useEffect(() => {
    // Prefetch possible page changes
    router.prefetch(`/access/enter-email?channelPathname=${channelPathname}`)

    if (fetchStatus === 'succeeded' && IsWhiteLabelDataLoaded) {
      // Need to wait for fetch to complete. If we pass channelPrivacyType from [channel_page]
      // It gets privacy type at build time and not upon fetch so updating channel type does nothing
      // Need to also wait for whiteLabel data to be loaded
      validatePageAccess()
      checkSearchParam()
    }
  }, [fetchStatus, IsWhiteLabelDataLoaded])

  // Flags
  const isCheerIqFlagActive = useSelector(selectAppFeatureFlagStatusByName('flagCheerdanceIqPage'))

  const channelPrivacyType =
    useAppSelector(selectChannelPropByChannelPathname(channelPathname)('channelPrivacyType')) ?? ''

  const handleUserChannelAccess = (channel: ChannelInterface, email: string) => {
    switch (channel.userChannelAccess.channelAccessState) {
      case ChannelAccessEnum.NO_ACCESS:
      case ChannelAccessEnum.DEACTIVATED:
      case ChannelAccessEnum.AWAITING_ACTIVATION:
        setDeniedEmail(email)
        setPageState('denied')
        break
      case ChannelAccessEnum.ACTIVE:
        setPageState('access')
        break
    }
  }

  const privateChannelValidation = () => {
    let queryParams: GetByChannelPathNameParamsInterface = {
      roles: true,
      draft: false,
      subscriptions: true,
      private: true,
    }
    let getChannelByPathnameApi = getChannelByPathname

    if (isCheerIqFlagActive) {
      queryParams = {
        ...queryParams,
        showCategorySlug: true,
      }
      getChannelByPathnameApi = getChannelByPathnameV2
    }

    fbAuth.onAuthStateChanged(async (user) => {
      if (user) {
        getChannelByPathnameApi(channelPathname, queryParams)
          .then(async (channel) => {
            handleUserChannelAccess(channel, user.email)
            if (channel.roles && isGreaterThanZero(channel.roles.length)) {
              const creator = await getUserById(channel.roles[0].userId)
              setRequestEmail(creator.email)
            } else {
              setRequestEmail(VIDZING_SUPPORT_EMAIL)
            }
          })
          .catch((e) => {
            // This error will occur if user has no channel access as the call to creators MS from contents
            // will throw an error as the user doesn't have channel access. In that case access denied
            setDeniedEmail(user.email)
            setPageState('denied')
          })
      } else {
        /**
         * User may have already tried to access the channel so check if there
         * is sessionStorage data denying the user access
         */
        const channelDeniedSession = sessionStorage.getItem(CHANNEL_ACCESS_STATUS_SESSION_KEY) ?? ''
        const channelDeniedEmailSession =
          sessionStorage.getItem(CHANNEL_ACCESS_DENIED_EMAIL_SESSION_KEY) ?? ''

        if (channelDeniedSession === ChannelAccessStatusSessionEnum.DENIED) {
          setDeniedEmail(channelDeniedEmailSession)
          setPageState('denied')
        } else {
          // If no session storage data un authed user is trying to access so send to access log in flow
          router.push(`/access/enter-email?channelPathname=${channelPathname}`)
        }
      }
    })
  }

  const validatePageAccess = () => {
    switch (channelPrivacyType) {
      case 'public':
      case 'unlisted':
        setPageState('access')
        break
      case 'private':
        privateChannelValidation()
        break
    }
  }
}
