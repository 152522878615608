import { motion } from 'framer-motion'
import { ReactElement } from 'react'
import styles from './styles.module.scss'
export default function SquareLoader(): ReactElement {
  const variants = {
    initial: {
      scaleY: 0.5,
      opacity: 0,
    },
    animate: {
      scaleY: 1,
      opacity: 1,
      transition: {
        repeat: Infinity,
        duration: 1,
        ease: 'circIn',
        repeatType: 'mirror' as const,
      },
    },
  }

  return (
    <motion.div className={styles.square}>
      <motion.div
        transition={{
          staggerChildren: 0.25,
        }}
        initial="initial"
        animate="animate"
        className={styles.content}
      >
        <motion.div variants={variants} className={styles.box} />
        <motion.div variants={variants} className={styles.box} />
        <motion.div variants={variants} className={styles.box} />
        <motion.div variants={variants} className={styles.box} />
        <motion.div variants={variants} className={styles.box} />
      </motion.div>
    </motion.div>
  )
}
