import type { ReactNode } from 'react'
import { Button } from '../../../_buttons'
import { CheckedOutIcon } from '../../_icons'
import { DispatchNewParamsContext } from '@components/dialog-provider'
import { useContext } from 'react'
import { ChannelSubscribeStateType } from '@shared/types/channel-subscribe-state.type'
import { useAppSelector } from '@root/store'
import {
  selectChannelThemeByChannelPathname,
  selectSubscriptionDataForEventTracking,
} from '@store/channel-slice/_selectors'
import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import { trackEvent } from '@utils/event-tracking'

export type Props = {
  channelPathname: string
  subscriptionStatus: ChannelSubscribeStateType
  subscriptionId: string
  children?: string
}

export function ToSubscribeButton({
  channelPathname,
  subscriptionStatus,
  subscriptionId,
  children = 'Subscribe',
}: Props): JSX.Element {
  const dispatch = useContext(DispatchNewParamsContext)
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  // Get the event tracking data for the event tracking triggers
  const eventTrackingData = useAppSelector(
    selectSubscriptionDataForEventTracking({ subscriptionId, channelPathname }),
  )
  function subscribe(): void {
    // Trigger tracking event
    trackEvent(TrackingEventsEnum.ADD_TO_CART, eventTrackingData)
    dispatch({
      dialogType: 'subscribe',
      channelPathname,
      subscriptionId,
      // Remove any anchors in the url
      url: window.location.href.split('#')[0],
    })
  }

  const buttonMap: Record<ChannelSubscribeStateType, ReactNode> = {
    isSubscribed: (
      <Button Icon={CheckedOutIcon} variant={'dottedBorder'} channelTheme={channelTheme}>
        Subscribed
      </Button>
    ),
    isNotSubscribed: (
      <Button onClick={subscribe} variant={'primary'} channelTheme={channelTheme}>
        {children}
      </Button>
    ),
    isLoading: (
      <Button onClick={() => undefined} isLoading={true} channelTheme={channelTheme}>
        {children}
      </Button>
    ),
    isNotSubscribable: <></>,
  }

  return <>{buttonMap[subscriptionStatus]}</>
}
