import { Button } from '../../../_buttons'
import { useAppSelector } from '@root/store'
import {
  selectChannelThemeByChannelPathname,
  selectPassDataForEventTracking,
} from '@store/channel-slice/_selectors'
import { selectPassStatusByPassId } from '@store/pass-slice/_selectors'
import { ReactNode, useContext } from 'react'
import { DispatchNewParamsContext } from '@components/dialog-provider'
import AccessBadge from '@ui/access-badge'
import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import { trackEvent } from '@utils/event-tracking'

interface Props {
  passId: string
  channelPathname: string
}

export function ToBuyChannelPassButton({ passId, channelPathname }: Props): JSX.Element {
  const dispatch = useContext(DispatchNewParamsContext)
  const passStatusSelected = useAppSelector(selectPassStatusByPassId(passId))
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  // Get the event tracking data for the event tracking triggers
  const eventTrackingData = useAppSelector(
    selectPassDataForEventTracking({ passId, channelPathname }),
  )

  function buy(): void {
    // Trigger tracking event
    trackEvent(TrackingEventsEnum.ADD_TO_CART, eventTrackingData)

    dispatch({
      dialogType: 'pass',
      channelPathname,
      url: window.location.href.split('#')[0], // Remove any anchors in the url
      passId,
    })
  }

  const passStatus = passStatusSelected?.isActive ? 'Active' : 'Inactive'

  const buttonMap: Record<typeof passStatus, ReactNode> = {
    Active: <AccessBadge channelPathname={channelPathname} label={'Purchased'} />,
    Inactive: (
      <Button onClick={buy} variant={'primary'} channelTheme={channelTheme}>
        Buy now
      </Button>
    ),
  }

  return <>{buttonMap[passStatus]}</>
}
