import styles from './styles.module.scss'
import RenderIf from '@root/utils/render-if'
import { isEmptyArray } from '@root/utils/arrays'
import UseSelectWhiteLabelData from '@hooks/white-label/use-select-white-label-data'
import UseHandleClose from '@hooks/search/use-handle-close'
import useGetSearchResults from '@hooks/search/use-get-search-results'
import { ReactNode, useEffect, useState } from 'react'
import SearchResultBlock from '@ui/search-result/search-result-block'
import { ContentTypeLabelsEnum } from '@shared/enums/content-type-labels.enum'
import CloseIcon from '@ui/icons/close'
import SearchInput from '@ui/search/input'
import useChannelSearch from '@hooks/search/use-channel-search'
import { AnimatePresence } from 'framer-motion'
import SquareLoader from '@ui/loader/square'
import { useRouter } from 'next/router'
import { SearchRequest } from '@shared/interfaces/search.interface'

interface Props {
  channelPathname: string
  contentId?: string
}

export default function SearchResult({ channelPathname, contentId }: Props): JSX.Element {
  // Get search data
  const { isLoading, searchResults, searchData, searchText } = useGetSearchResults(channelPathname)

  // Check white label
  const { whiteLabel } = UseSelectWhiteLabelData()
  const hasWhiteLabel = !!whiteLabel

  // Use handle close hook for closing search results
  const handleClose = UseHandleClose({ channelPathname, contentId })

  const { setSearchValue } = useChannelSearch()

  // Create a search result map for all search content types
  const searchResultsMap: Record<ContentTypeLabelsEnum, ReactNode> = {
    [ContentTypeLabelsEnum.LIVE]: (
      <SearchResultBlock
        results={searchResults.liveResults}
        contentType={ContentTypeLabelsEnum.LIVE}
        channelPathname={channelPathname}
      />
    ),
    [ContentTypeLabelsEnum.VOD]: (
      <SearchResultBlock
        results={searchResults.vodResults}
        contentType={ContentTypeLabelsEnum.VOD}
        channelPathname={channelPathname}
      />
    ),
    [ContentTypeLabelsEnum.AUDIO]: (
      <SearchResultBlock
        results={searchResults.audioResults}
        contentType={ContentTypeLabelsEnum.AUDIO}
        channelPathname={channelPathname}
      />
    ),
    [ContentTypeLabelsEnum.BUNDLE]: (
      <SearchResultBlock
        results={searchResults.bundleResults}
        contentType={ContentTypeLabelsEnum.BUNDLE}
        channelPathname={channelPathname}
      />
    ),
    [ContentTypeLabelsEnum.EXTERNAL_LINK]: (
      <SearchResultBlock
        results={searchResults.externalLinkResults}
        contentType={ContentTypeLabelsEnum.EXTERNAL_LINK}
        channelPathname={channelPathname}
      />
    ),
    [ContentTypeLabelsEnum.CHAPTER]: (
      <SearchResultBlock
        results={searchResults.chapterResults}
        contentType={ContentTypeLabelsEnum.CHAPTER}
        channelPathname={channelPathname}
      />
    ),
  }

  // Normal Search without category
  const onSearch = (search: SearchRequest) => {
    const { word } = search
    setSearchValue(word)
  }

  return (
    <div className={styles.wrapper}>
      <SearchInput
        onSearch={onSearch}
        searchBy={searchText}
        backButton={
          <button onClick={handleClose}>
            <span>Close</span>
            <CloseIcon />
          </button>
        }
      />
      <div className={styles.results}>
        <AnimatePresence>{isLoading && <SquareLoader />}</AnimatePresence>
        <RenderIf isTrue={!isLoading}>
          {/**
           * This rendering controls the order of the search results on
           * the page so can be moved around easily to update order
           * if required. Can be ordered via BE if we need it one day
           * */}
          {searchResultsMap[ContentTypeLabelsEnum.CHAPTER]}
          {searchResultsMap[ContentTypeLabelsEnum.LIVE]}
          {searchResultsMap[ContentTypeLabelsEnum.EXTERNAL_LINK]}
          {searchResultsMap[ContentTypeLabelsEnum.VOD]}
          {searchResultsMap[ContentTypeLabelsEnum.BUNDLE]}
          {searchResultsMap[ContentTypeLabelsEnum.AUDIO]}
        </RenderIf>

        {/* No results */}
        <RenderIf isTrue={isEmptyArray(searchData) && !isLoading}>
          <div className={styles.title}>No results available</div>
        </RenderIf>
      </div>
    </div>
  )
}
