import Slider from '@ui/slider'
import { CARD_SLIDER_CONFIG } from './_constants'
import { useAppSelector as useSel, useAppSelector } from '@root/store'
import styles from './styles.module.scss'
import {
  selectAllChannels,
  selectChannelPropByChannelPathname,
  selectChannelThemeByChannelPathname,
  selectStatus,
  selectSuperRailChannelNamesByChannelPathname,
} from '@store/channel-slice/_selectors'
import { CREATORS_TO_WATCH } from '@shared/constants/rails.constants'
import { useMemo, useState } from 'react'
import { HALF_SECOND_MS } from '@root/constants'
import CreatorsSkeletonRail from '@ui/rails/skeleton/creators'
import RenderIf from '@utils/render-if'
import { CreatorRailLogo } from '@ui/rails/creators/creator'

export interface Props {
  channelPathname: string
}

export default function SuperChannelCreatorRailsV2({ channelPathname }: Props): JSX.Element {
  const { settings, showButtons, buttonsVariant } = CARD_SLIDER_CONFIG

  const channelNames = useAppSelector((state) =>
    selectSuperRailChannelNamesByChannelPathname(state, channelPathname),
  )

  const superChannelRail = useAppSelector(
    selectChannelPropByChannelPathname(channelPathname)('superChannelRail'),
  )

  const fetchStatus = useAppSelector(selectStatus)

  const title = superChannelRail?.name ?? ''
  const [loading, setIsLoading] = useState<boolean>(true)

  const renderCardRail = channelNames.map((subChannelPathname) => (
    <CreatorRailLogo
      key={subChannelPathname}
      channelPathname={subChannelPathname}
      mainChannelName={channelPathname}
    />
  ))

  useMemo(() => {
    if (fetchStatus === 'succeeded') {
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, HALF_SECOND_MS)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [fetchStatus])

  return (
    <>
      <RenderIf isTrue={loading}>
        <CreatorsSkeletonRail />
      </RenderIf>
      <RenderIf isTrue={!loading}>
        <section className={styles.railCards} aria-label={title}>
          <h2 className={styles.title}>{title}</h2>
          <Slider
            elements={renderCardRail}
            settings={settings}
            showButtons={showButtons}
            buttonsVariant={buttonsVariant}
            isRailContent
          />
        </section>
      </RenderIf>
    </>
  )
}
