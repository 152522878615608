import style from './common.module.css'
import { Intro as IntroV1 } from './intro'
import { CoverImage } from '@features/_ui/cover-image'
import useChannelImages from '@hooks/use-channel-images'
import { parseBoolean } from '@utils/connascence'
import { useAppSelector } from '@root/store'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { IntroV2 } from '@components/channel-page/main/channel-header/intro-v2'

export type Props = {
  channelPathname: string
  bannerImage: string
  avatar: string
}

export function ChannelHeader({ channelPathname, bannerImage, avatar }: Props): JSX.Element {
  // Hook to get the channel images to use. We want to use the bannerImage and avatar passed from static props if the same as the channel data from the BE
  const { bannerImageToUse, avatarToUse, bannerImageAlt } = useChannelImages({
    bannerImage,
    avatar,
    channelPathname,
  })

  const isNewTheme = parseBoolean(process.env.VIDZING_THEME_2024)
  const mobileChannelHeaderFlag = useAppSelector(
    selectAppFeatureFlagStatusByName('flagBugMobileChannelHeader'),
  )
  const Intro = mobileChannelHeaderFlag ? IntroV2 : IntroV1

  return (
    <section
      aria-label="Head Image and Intro"
      className={`${style.section} ${isNewTheme ? style.vidzing2024 : ''}`}
    >
      <CoverImage image={bannerImageToUse} imageAlt={bannerImageAlt} />
      <Intro channelPathname={channelPathname} avatar={avatarToUse} />
    </section>
  )
}
