import { selectChannelPropByChannelPathname as sel } from '@store/channel-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import styles from './index.module.css'
export type Props = {
  channelPathname: string
}

export function ChannelDescription({ channelPathname }: Props): JSX.Element {
  const description = useSel(sel(channelPathname)('description'))

  return <p className={styles.description}>{description}</p>
}
